import React from 'react';

import Layout from "../components/layout";
import { Title } from '../components/Globals';
import { Link } from 'gatsby';
import {Helmet} from "react-helmet";



const IndexPage = ({ data }) => {

  const handleSubmit = (event) => {
    event.preventDefault();
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
    let formData = new FormData();
    formData.append('from', event.target.email.value);
    formData.append('to', 'sandip@cymetrixsoft.com');
    formData.append('subject', event.target.subject.value);
    formData.append('text', event.target.message.value);
    fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
      method: 'post',
      headers: headers,
      body: formData,
    })
    .then(function (data) {
      if(data.status === 200) {
        //alert('Message sent successfuly');
        window.location = 'https://www.cymetrixsoft.com/Thankyou/';
      }
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });
  }

  const [services] = React.useState([
    {
      id: 1,
      title: 'Salesforce Consulting',
      desc: 'Cymetrix is an offshore software development company and is a experienced Salesforce Consulting partner, who has helped customers across different Industry clouds. We redefine your Salesforce Consulting verticals by Sales, Service, Marketing Cloud and will help you start and implement salesforce products into your business process by using best practices for salesforce implementation',
      image: 'salesforceconsulting.png',
      alt: 'Best CRM Software Image - Salesforce',
      imagetitle:' Salesforce Consulting Services - Cymetrix Software',
      url: {
        title: 'Salesforce Consulting Services',
        url: '/services/salesforce-consulting/'
      }
    },
    {
      id: 2,
      title: 'Looker Consulting',
      desc: 'Cymetrix provides end-to-end Looker professional services and implementation services covering impulse evaluation, collection of business needs, looker implementation services, looker data services, embedded analytics with Looker APIs, data model design ie LookML, data integration, prototype report creation, and interface design,  and collectively provide comprehensive end-user training to optimize return on investors.',
      image: 'Lookerconsulting.png',
      alt :'Looker Consulting Service Image - Cymetrix Software',
      imagetitle:'Looker | Professional Services',
      url: {
        title: 'Looker Consulting Services',
        url: '/services/looker-consulting/'
      }
    },
    {
      id: 3,
      title: 'Data & Analytics Consulting',
      desc: 'Delivering insight into your business is no longer elective. It’s table stakes. Cymetrix offers data analytics and visualization services and its strategic data analytics consultants are force multipliers for our clients and have deep domain experience across the full data stack to assist solve your challenges in weeks, not years.',
      image: 'googleCloud.png',
      alt: 'Data and Analytics Consulting Service Image - Cymetrix Software',
      imagetitle:'Big Data and Analytics Consulting Services',
      url: {
        title: 'Data & Analytics Consulting Services',
        url: '/services/data-analytics/',
       
      }
    },
    {
      id: 4,
      title: 'Marketing Automation',
      desc: 'We distinguishe ourselves through our end-to-end marketing automation services. It includes Salesforce Marketing Cloud implementation, creation and management of marketing campaigns & content development, along with an integrated strategy which supports a unified CX.',
      image: 'cymetrix-marketing-automation.png',
      imagetitle:'Marketing Automation Services',
      alt:'Marketing Automation in India and USA by Cymetrix Software',
      url: {
        title: 'Marketing Automation Services',
        url: '/services/marketing-automation-services/'
      }
    },
  ])
  return (
  <Layout>
    
    <div className="flex flex-col items-center">
      <Helmet>
        <title>Cymetrix Software | Top CRM & Analytics Company In USA</title>
        <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india,  Cymetrix Software, cymetrixsoft"/>
        <meta name="description" content="Cymetrix Software is a niche software development company based In USA, India, UK and Japan. The company offers word class CRM implementation and business analytics services."/>
        <meta name="p:domain_verify" content="4e0ea5f1cab120f245164ce6d5ef5f7e"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/" /> 
      </Helmet>
      <div className="home-background h-screen flex flex-col w-full">
        <h1 className="block text-base xl:text-5xl xl:px-48 xl:mt-24 md:mt-8 sm:mt-20 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
        text-white text-uppercase text-center">
          EXECUTE YOUR WINNING STRATEGIES WITH OUR GROWTH TECHNOLOGIES
       
          </h1>
        <h2 className="block text-base xl:text-2xl md:mt-6 lg:text-lg md:text-base sm:text-base sma:text-base
        text-white text-uppercase text-center">
     Cymetrix is a trusted partner for Salesforce consulting and data analytics technology solutions				
        </h2>
      </div>

      <main className="w-full flex flex-col items-center">
        <div className="max-w-5xl sm:mx-4">
          <div className="flex flex-col sm:flex-row items-center my-16 sm:mx-10">
            <div className="sm:w-3/6">
            <img className="h-full w-full" title="Cymetrix Software- Top software development company" alt="Top CRM company in USA and India" src={require('../images/home/web-banner.png')} />
            </div>
            <div className="sm:w-3/6 flex flex-col items-start mt-8 sm:mt-0 mx-3 " >
                <h1 className="text-3xl leading-tight text-header font-hairline">Driving Growth In Action</h1>
                <p className="leading-normal text-base pt-4  text-gray-600 text-justify">Welcome to Cymetrix Software - your trusted partner for Salesforce consulting and data analytics technology solutions. Our team of experts is passionate about helping businesses succeed and is led by experienced technologists who inspire, coach, and co-create with our clients to build a shared vision and commitment to success. Cymetrix Software has helped many companies to solve their complex business problems with our advanced business analytics as well as CRM solutions using Salesforce . We deliver success through the best practices for Salesforce implementation and transformative cloud-based business solutions in partnership with our customers. Our highly focused and specialized technology solutions provide in-depth and comprehensive coverage of unique business needs.
                                      							
</p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="font-hairline text-center">
              <h1 className="w-full my-2 text-3xl text-header">HERE'S HOW WE CAN HELP BY PROVIDING VALUE TO YOU</h1>
              <p className="mt-5">Business growth technologies we offer</p>
            </div>

            <div className="flex flex-col sm:flex-row sm:-mx-2">
              <div className="mt-4 sm:w-1/3">
                <div className=" h-full p-8 flex flex-col items-center sm:mx-2">
                    <div className="bg-gray-200 text-indigo-700 w-24 rounded-full p-2">
                       <img className="h-20 w-20 fill-current" title="Best CRM Software Icon" alt="Salesforce CRM Services - Cymetrix Software" src={require('../images/home/salesforce.png')} />
                    </div>
                    <div className="mt-4 font-thin">Salesforce</div>
                </div>
              </div>
              
              <div className="mt-4 sm:w-1/3">
                <div className=" h-full p-8 flex flex-col items-center sm:mx-2">
                  <div className="bg-gray-200 text-indigo-700 w-24 rounded-full p-2">
                    <img className="h-20 w-20 fill-current" title="Data Analytics Icon | Cymetrix Software" alt="Data Analytics Icon Icon image - Cymetrix Software" src={require('../images/home/dataanalytics.png')} />
                  </div>
                  <div className="mt-4 font-thin">Data Analytics</div>
                </div>
              </div>
              
              <div className="mt-4 sm:w-1/3">
                <div className=" h-full p-8 flex flex-col items-center sm:mx-2">
                  <div className="bg-gray-200 text-indigo-700 w-24 rounded-full p-2">
                      <img className="h-20 w-20 fill-current" title="Fintech Solution Icon | Cymetrix Software" alt="Fintech Solution Icon image - Cymetrix Software" src={require('../images/home/fintech.png')} />
                  </div>
                  <div className="mt-4 font-thin">Marketing Automation</div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="bg-gray-300 w-full flex flex-col items-center">
          <div className="max-w-5xl mx-4 sm:mx-2 sma:mx-2">
            <h1 className="text-3xl w-full text-gray-800 text-uppercase text-center mt-20 mb-5 md:text-3xl sma:text-xl">
SALESFORCE PARTNER SINCE 2017</h1>
            <div className="flex flex-col md:flex-row items-center content-between my-10">
              <img src={require('../images/home/SalesforcePartner.png')} title="Cymetrix Software- Salesforce Partner" alt="Salesforce Partner Cymetrix Software" className="w-48" />
              <p className="mx-5 sma:my-10 text-gray-700 text-justify "> 
              Cymetrix is one of the leading  <Link to="https://www.cymetrixsoft.com/services/salesforce-consulting" className="underline">Salesforce consulting companies</Link> with experience and expertise to drive Salesforce-powered digital transformation for your enterprise. Our consultants can help clients leverage the Salesforce platform effectively to innovate and create value from customer operations.</p>c
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center my-10 mb-20">
              <img src={require('../images/home/SalesforceOrg.png')}  alt="Cymetrix Software - Registered partner 2020 Image" title="Cymetrix Software | Salesforce.org Registered Partner" className="w-48 h-30"  />
              <p className="mx-5 sma:my-10 text-gray-700 text-justify">Our relationship with clients, staff, and communities is imbibed within us as the company's vision and mission. These values inculcate the qualities of a leader, thereby guiding our business ways and nurturing our growth. We are top salesforce consulting companies in India  and we believe in delivering trust, ethics, and confidentiality to our clients.</p>
            </div>
          </div>
        </div>

        <div className="service-background w-full flex flex-col items-center">
          <div className="max-w-6xl mx-4 sma:mb-4">
          
          <Title title="CYMETRIX SOFTWARE SERVICES" classValue={'text-white sma:text-xl text-4xl'}/>
          
          
            <div className="flex flex-col sm:flex-row sm:-mx-2">
            {
              services.map(service => {
                  return <div className="mt-4 md:w-1/2 lg:w-1/4" key={service.id}>
                    <div className="bg-white p-8 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                        <div className="text-indigo-700 w-32 flex items-center justify-center h-32 p-2 rounded-full">
                          <img src={require(`../images/services/${service.image}`)} className="w-20 h-20" alt={service.alt} title={service.imagetitle}/>
                        </div>
                        <Link to={service.url.url} className="mt-5 text-hover text-lg  text-center">{service.url.title}</Link>
                        <p className="mt-5 text-sm text-gray-600  text-center">{service.desc}</p>
                        
                    </div>
                  </div>
              })
            }
            </div>
          </div>
        </div>


        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <h1 className="mt-5 text-xl font-semibold">Featured Transformations</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../images/services/salesforce-partner-india-fintech.png')} alt="crm and automation case study | Cymterix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/fintech-community-cloud/" >Lending Transformation: Powered by Salesforce and Cymetrix Accelerator </Link></h1>
                          
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../images/services/crm-services-company-usa-india-mumbai.png')} alt="top crm company Usa India | Cymetrix" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/salesforce-digital-marketing-usa/">Driving Results with Salesforce Marketing Cloud: A Comprehensive Digital Marketing Transformation</Link></h1>
                            
                        </div>
                    </div>
                </div>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../images/services/salesforce-consulting-services-usa-pharma.png')} alt="Salesforce consulting partners pharma case study | Cymterix Software" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/b2b-commerce-cloud-pharma/" >Streamlining global wholesale operations with Salesforce & SAP for a pharmaceutical company</Link></h1>
                          
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../images/services/crm-company-india.jpg')} alt="crm companies in mumbai india usa | Cymetrix" title="" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/customer-stories/salesforce-nonprofit-case-study-usa/">Clicks to Cash: Driving NPO fundraising campaign with Salesforce</Link></h1>
                            
                        </div>
                    </div>
                </div>
            </div>


        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">Discover Our Insights, Events and News</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../images/services/salesforce-partners-license-types.png')} alt="Cymterix" title="Salesforce license types" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blogs.cymetrixsoft.com/2023/11/02/understanding-salesforce-license-types-for-optimization-and-cost-reduction/" >Understanding Salesforce License Types For Optimization and Cost Reduction</Link></h1>
                            <p className="text-lg">Read about the core attributes of Salesforce.com licensing agreements.</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../images/services/marketing-automation-roi.png')} alt="CRM marketing automation | Cymetrix Software" title="Maximizing RoI with data and automation" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://www.cymetrixsoft.com/webinars/marketing-automation-roi-webinar/">Maximize your Marketing ROI with Data and Marketing Automation</Link></h1>
                            <p className="text-lg">Find out how to use data & marketing automation to maximize RoI.</p>
                        </div>
                    </div>
                </div>
            </div>

        <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
            <img src={require('./../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
        </div>

        <div className="my-10 md:max-w-screen-md">
          <div className="flex flex-col items-center">
            <img src={require('../images/home/salesforce-consulting-partner.png')} className="w-48 h-30" alt=""/>
            <Title title="GET IN TOUCH" classValue={'text-black text-4xl'} />
          </div>
          
          <div className="sm:flex sma:mt-5">
            <div className="md:w-3/4">
              <form className="justify-center flex" onSubmit={handleSubmit}>
                <div className="bg-white rounded sma:mx-3 px-8 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
                  <div className="-mx-3 md:flex mb-6 md:space-x-2">
                    <div className="md:w-1/2 mb-6 md:mb-0">
                      <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                        Enter Your Name*
                      </label>
                      <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" id="name" name="name"
                            type="text" placeholder="" required/>
                    </div>
                    <div className="md:w-1/2">
                      <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                        Enter Your Email*
                      </label>
                      <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" id="email" name="email"
                            type="email" placeholder="" required/>
                    </div>
                  </div>
                  <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                      <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                        Enter Your Subject*
                      </label>
                      <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                            id="subject" type="text" placeholder="" name="subject" required/>
                    </div>
                  </div>
                  <div className="-mx-3 md:flex mb-6">
                    <div className="md:w-full">
                      <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                        Enter Your Message*
                      </label>
                      <textarea className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3 h-48"
                                id="message" type="text" placeholder="" name="message" required>
                      </textarea>
                    </div>
                  </div>
                  <div className="-mx-3 md:flex mt-2">
                    <div className="sma:w-full">
                      <button
                        className="sma:w-full bg-header text-white font-bold py-2 px-4 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="sma:mx-3 sm:flex flex-col md:w-1/4 mt-2">
              <div className="bg-gray-200 h-auto p-5 mb-2 border-solid border-2 border-blue-900">
                <h1 className="text-2xl">USA OFFICE</h1>
                <p className="text-lg my-4"><i className="mdi mdi-map-marker h-6 w-6" aria-hidden="true" /> 5704 Cherry Ave # 144, Long Beach, CA, 90805-4404, United States</p>
                <p className="text-lg my-4"><i className="mdi mdi-email h-6 w-6" aria-hidden="true" /> info@cymetrixsoft.com</p>
                <p className="text-lg"><i className="mdi mdi-phone h-6 w-6" aria-hidden="true" /> +1-650-334-4141</p>
              </div>

              <div className="bg-gray-200 h-auto p-5 mt-2 border-solid border-2 border-blue-900">
                <h1 className="text-2xl">INDIA OFFICE</h1>
                <p className="text-lg my-4"><i className="mdi mdi-map-marker h-6 w-6" aria-hidden="true" /> 12, 1st Floor, Samrudhi Venture Park, MIDC, Andheri East, Mumbai 400093, India</p>
                <p className="text-lg my-4"><i className="mdi mdi-email h-6 w-6" aria-hidden="true" /> info@cymetrixsoft.com</p>
                <p className="text-lg"><i className="mdi mdi-phone h-6 w-6" aria-hidden="true" /> +919137699685 , +918655343081</p>
              </div>
            </div>
          </div>
        </div>

        <section className="bg-bgcolor h-auto mb-10">

      <div className="lg:container mx-auto">
        <div className="sma:p-2 mt-8">
          <h1 style={{color: '#2b56a9'}} className="text-xl font-bold">CLIENT TESTIMONIALS</h1>
          <p className="mt-5 text-gray-700"><strong>Developed business applications, implemented Salesforce CRM, developed CRM application:</strong> I recently happened to work with the expert team of Cymetrix that helped us to grow CRM applications where we encounter major difficulties. They provided an excellent solution that reduced frequent error in the working systems. If you want to succeed in the modern business world by making good CRM interfaces, then I would highly recommend the services which are offered by Cymetrix. <strong>-Pawan Misra CEO Hyper Source</strong>
          </p>
          <p className="mt-5 text-gray-700">Cymetrix delivered Looker implementation and also data analytics. It’s been a great experience while working with the Cymetrix team. They assisted us in every parameter that requires attention and it is advisable to go with the instinct as they suggested. I would rate them 10/10 and will recommend their consulting services. <strong>-Adam Spisak Ex-Director Consulting Service Jaggaer </strong>            
          </p>
          <p className="mt-5 text-gray-700"> <strong>Supporting Salesforce with a team of 15 Salesforce engineers: </strong>I was hustling to understand the new era of business but Cymetrix raised a helping hand in providing their essential support and services. Within a short period of span, our business is able to improve productivity and visibility. It would be a great privilege to recommend the services of Cymetrix to others.<strong>- Amit Singh Enel X</strong></p>
          <p className="mt-5 text-gray-700"> <strong>Developed lending software on Salesforce platform  force.com: </strong> We choose Cymetrix for developing lending software on the Salesforce platform and that’s one of the best decisions we made. We were impressed by the amazing customer service and everything gets done ahead of time with great Quality.  Our business model reaches the highest peak in order to make a great profit ratio.<strong>-Inderjit Matta Co-founder & CTP, GMoney a co-lending platform</strong></p>
          <p className="mt-5 text-gray-700"> <strong>Implemented Salesforce: </strong>We would like to take a moment in order to show our gratitude and would like to say thanks to Cymetrix for implementing Salesforce for us. Their team was extremely talented and helpful throughout the project. We would love to recommend Cymetrix services to everyone! It’s been an amazing journey to work with Cymetrix. <strong>-Bala Sista Ex Vice President Aira Inc</strong></p>

        </div>


      </div>
    </section>


        
      </main>
    </div>
  </Layout>
  )
};

export default IndexPage;
